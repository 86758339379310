






import Vue from 'vue';
import Vuetify from 'vuetify';
import Component from 'vue-class-component';

@Component({
  vuetify: new Vuetify(),
})
export default class Dashboard extends Vue {
}
